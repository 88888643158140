<template>
  <!-- 右边 -->
  <div class="shaoxingentirety">
     <div class="space">
            
            <div class="floorSpace darkBack" @click="skipathlete('athlete', $event)">
                <div class="skipshao">
                    <span>注册运动人数</span>
                    <img src="../../../assets/images/jumparrow.png" alt="">
                </div>
                <p style="font-size:0.26rem;color:#08A4FD;font-weight: bold;">
					<countTo :startVal='0' :endVal='homeData.sportsman_main_sum' :duration='3000'></countTo>
				</p>
            </div>
			<div class="floorSpace darkBack">
                <div class="skipshao">
                    <span>经常性锻炼人口占比</span>
                </div>
			    <p style="font-size:0.26rem;color:#08A4FD;font-weight: bold;">
					<countTo :startVal='0' :endVal='number.proportion' :duration='3000'></countTo>%
				</p>
			</div>
        </div>
    <!-- <div class="darkBackRight entiretytop">
      <div>
        <p class="often_text" style="color: #ffffff; font-size: 0.14rem;">
          经常性锻炼人口
        </p>
        <p style="color: #08a4fd; font-weight: bold">
          <countTo
            :startVal="0"
            :endVal="number.population"
            :duration="3000"
          ></countTo>
        </p>
      </div>
      <div>
        <p class="often_text" style="color: #ffffff; font-size: 0.14rem;">
          经常性锻炼人口占比
        </p>
        <p style="color: #08a4fd; font-weight: bold">
          <countTo
            :startVal="0"
            :endVal="number.proportion"
            :decimals="1"
            :duration="3000"
          ></countTo
          >%
        </p>
      </div>
    </div> -->
    <div class="darkBackRight entiretytop">
       <img src="../../../assets/images/sport_video_title.png" class="right-title"/>
           <video id="videoID" controls="true"
           :src="currentVideo"
           style="object-fit:fill"
           class="horizontal-img"
           preload="metadata"
           webkit-playsinline="true"
           playsinline="true"
           x-webkit-airplay="allow"
           x5-video-player-type="h5"
           x5-video-player-fullscreen="true"
           x5-video-orientation="landscape"
            autoplay muted>
            <!-- <source :src="currentVideo" type="video/mp4" /> -->
            抱歉，您的浏览器不支持内嵌视频!
    </video>
    </div>
    <div class="darkBackRight entiretybottom">
      <img src="../../../assets/images/sport_people_title.png" class="right-title"/>
      <!-- 上半部分 -->
      <!-- <div class="flex marginTop20">
        <div class="width50">
          <div class="blueFont font16 fitness_text">● 运动年龄分布</div>
          <div id="sportAge"></div>
        </div>
        <div>
          <div class="blueFont font16 fitness_text">● 运动时间分布</div>
          <div id="sportTime"></div>
        </div>
      </div> -->
      <!-- 下半部分图表 -->
      <div class="flex marginTop10">
        <!-- 左侧图表 -->
        <div class="width40 right-border">
          <div>
            <div class="blueFont font16 fitness_text">● 男女比例</div>
            <div class="sex">
              <div>
                <img style="width:0.49rem;height:0.57rem" :src="tinct1.img" />
                <div class="lightBlueFont font16">{{ tinct1.gender }}</div>
              </div>
              <div id="malefemaleRatio"></div>
            </div>
            <div class="sex">
              <div>
                <img style="width:0.49rem;height:0.57rem" :src="tinct2.img" />
                <div class="lightBlueFont font16">{{ tinct2.gender }}</div>
              </div>
              <div id="malefemaleRatio2"></div>
            </div>
          </div>
        </div>
        <!-- 右侧图表 -->
        <div class="sports ">

            <div class="width50">
          <div class="blueFont font16 fitness_text">● 运动年龄分布</div>
          <div id="sportAge"></div>
        </div>
           <div class="blueFont font16 fitness_text">● 运动时间分布</div>
          <div id="sportTime"></div>
        </div>
          <!-- <div class="blueFont font16 fitness_text">● 运动喜好</div>
          <div id="movementPreferences"></div> -->
        </div>
      </div>
    </div>
</template>

<script>
// 引入数字滚动组件
import countTo from "vue-count-to";
import { mapState } from "vuex";
import { getAjax, timestamp } from "/src/assets/js/websocket";
export default {
  components: { countTo,
  },
  data() {
    return {
      number: {
        population: 0,
        proportion: 0,
      },
      // tinct1:getAjax("主页-体育人群画像-男比例", (res) => {this.tinct1 = JSON.parse(res.data)}),
      // tinct1: "",
      // tinct2: "",
      // sport: "",
      // age: "",
      // timer: "",
      rightTopTimer: "",
      tinct1: {
        gender: "男",
        color1: "#43BA6B", // 绿色
        color2: "#333453",
        value: "55",
        img: require("../../../assets/images/man.png"),
      },
      tinct2: {
        gender: "女",
        color1: "#FF79FF", // 紫色
        color2: "#333453",
        value: "45",
        img: require("../../../assets/images/woman.png"),
      },
      sport: {
        data: [
          "跑步",
          "健走",
          "骑行",
          "登山",
          "足球",
          "篮球",
          "羽毛球",
          "乒乓球",
          "广场舞",
          "健身",
          "其他",
        ],
        number: [
          "1000",
          "920",
          "860",
          "780",
          "700",
          "620",
          "540",
          "460",
          "380",
          "300",
          "220",
        ],
        max: 10,
        color3: "#E5813A", // 渐变色初始颜色
        color4: "#EFDC29", // 
      },

      age: [
        { value: 1048, name: "青少年" },
        { value: 735, name: "青年" },
        { value: 580, name: "中年" },
        { value: 484, name: "老年" },
      ],

      timer: [
        { value: 1048, name: "0-8点" },
        { value: 735, name: "8-13点" },
        { value: 580, name: "13-18点" },
        { value: 484, name: "18-24点" },
      ],
      currentVideoIndex: 0,
      currentVideo: '',
      playerOptions : {
                    playbackRates : [ 0.5, 1.0, 1.5, 2.0 ], //可选择的播放速度
                    autoplay : true, //如果true,浏览器准备好时开始回放。
                    muted : true, // 默认情况下将会消除任何音频。
                    loop : false, // 视频一结束就重新开始。
                    preload : 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language : 'zh-CN',
                    aspectRatio : '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid : true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources : [],
                    poster : "", //你的封面地址
                    // width: document.documentElement.clientWidth,
                    notSupportedMessage : '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar : false
                    // {
                    //     timeDivider : true,//当前时间和持续时间的分隔符
                    //     durationDisplay : true,//显示持续时间
                    //     remainingTimeDisplay : false,//是否显示剩余时间功能
                    //     fullscreenToggle : true,  //全屏按钮
                    // }
                }
    };
  },
  computed: {
    ...mapState(["homeData", "dataCon"]),
  },
  methods: {
    skipathlete(showdata, e) {
      let _this = this;
      show(showdata, _this);
      $("#amap").hide();
    },
    // 绘制运动年龄图
    drawSportAge(number) {
      // let myChart = this.$echarts.init(document.getElementById("sportAge"));
      let myChart = this.$echarts.getInstanceByDom(
        document.getElementById("sportAge")
      );
      if (myChart == null) {
        // 如果不存在，就进行初始化
        myChart = this.$echarts.init(document.getElementById("sportAge"));
      }

      let option = {
        legend: {
          // bottom: 0,
          right: 10,
          top: 20,
          itemWidth: 7,
          itemHeight: 7,
          itemGap: 5,
          textStyle: {
            //图例文字的样式
            color: "#7EA7E2",
            fontSize: 8,
          },
          orient: 'vertical',
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "40%"],
            // 在容器中的位置
            center: ["35%", "40%"],
            avoidLabelOverlap: false,
            labelLine: {
              show: true,
              //设置延长线的长度
              normal: {
                length: 7, //设置延长线的长度
                length2: 3, //设置第二段延长线的长度
                lineStyle: {
                  color: "#ffffff", // 改变标示线的颜色
                },
              },
            },
            // 字体颜色及大小
            label: {
              color: "#D2F8FF",
              textStyle: { fontSize: 10 },
            },
            color: ["#1D77D2", "#E35D68", "#1BBA79", "#F45E23"],
            data: number,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      // 图表自动缩放
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // 绘制运动时间分布图
    drawSportTime(number) {
      // let myChart = this.$echarts.init(document.getElementById("sportTime"));
      let myChart = this.$echarts.getInstanceByDom(
        document.getElementById("sportTime")
      );
      if (myChart == null) {
        // 如果不存在，就进行初始化
        myChart = this.$echarts.init(document.getElementById("sportTime"));
      }

      let option = {
        legend: {
          bottom: 0,
          right: 10,
          top: 20,
          itemWidth: 7,
          itemHeight: 7,
          itemGap: 5,
          textStyle: {
            //图例文字的样式
            color: "#7EA7E2",
            fontSize: 8,
          },
          orient: 'vertical',
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "40%"],
            // 在容器中的位置
            center: ["35%", "40%"],
            avoidLabelOverlap: false,
            labelLine: {
              show: true,
              //设置延长线的长度
              normal: {
                length: 7, //设置延长线的长度
                length2: 2, //设置第二段延长线的长度
                lineStyle: {
                  color: "#ffffff", // 改变标示线的颜色
                },
              },
            },
            // 字体颜色及大小
            label: {
              color: "#D2F8FF",
              textStyle: { fontSize: 10 },
            },
            color: ["#1D77D2", "#E35D68", "#1BBA79", "#F45E23"],
            data: number,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      // 图表自动缩放
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    pie(id, number) {
      // 基于准备好的dom，初始化echarts实例
      // var runningWater = this.$echarts.init(document.getElementById(id));
      let runningWater = this.$echarts.getInstanceByDom(
        document.getElementById(id)
      );
      if (runningWater == null) {
        // 如果不存在，就进行初始化
        runningWater = this.$echarts.init(document.getElementById(id));
      }

      // 指定图表的配置项和数据
      var waterOption = {
        // backgroundColor: '#F7F7F7',
        //全局颜色，顺序对应每个 series
        color: [number.color1, number.color2],
        series: [
          {
            name: "中间环形",
            type: "pie",
            //圆，半径，值1内圆，值2外圆
            radius: ["50%", "80%"],
            //饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标
            center: ["50%", "46%"],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            //数据标签
            label: {
              show: false,
              position: "center",
              textStyle: {
                fontSize: "0.16rem",
                fontWeight: "bold",
                color: "#fcf1cf", // 中间字体的颜色
              },
              formatter: "{c}%",
              // 针对 center 参数校正标签显示位置
              lineHeight: 3,
            },
            data: [
              {
                value: number.value,
                name: "",
                label: {
                  normal: {
                    show: true,
                  },
                },
              },
              {
                value: 100 - number.value,
                name: "",
              },
            ],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      runningWater.setOption(waterOption);
      // 图表自动缩放
      window.addEventListener("resize", function () {
        runningWater.resize();
      });
    },
    fitnes(id, number) {
      // let canye = this.$echarts.init(document.getElementById(id));
      let canye = this.$echarts.getInstanceByDom(document.getElementById(id));
      if (canye == null) {
        // 如果不存在，就进行初始化
        canye = this.$echarts.init(document.getElementById(id));
      }
      var data = number.number;
      // var data = [];
      // for (let i = 0; i < 11; ++i) {
      //     data.push(Math.round(Math.random() * 200));
      // }

      let option = {
        xAxis: {
          // max: 'dataMax',
          show: true,
          type: "value",
          splitNumber: 5,
          axisLine: {
            //x轴坐标轴
            show: false,
          },
          axisLabel: {
            show: false,
          },

          axisTick: {
            //x轴刻度线
            show: false,
          },
          splitLine: {
            // 网格线的颜色
            show: false,
          },
        },
        grid: {
          // 间距
          left: "16%",
          // right: '16%',
          top: "2%",
          bottom: "1%",
          containLabel: true,
        },
        yAxis: {
          type: "category",
          data: number.data,
          inverse: true, // 上下反向
          animationDuration: 300,
          animationDurationUpdate: 300,
          max: number.max, // y轴显示显示最大的条数
          axisLine: {
            // y轴左侧的样式
            show: false,
          },
          splitLine: { show: false },
          axisTick: {
            // y轴刻度线
            show: false,
            alignWithLabel: true,
          },
          splitArea: { show: false },
          axisLabel: {
            // y轴字体的颜色
            // margin: 176,//刻度标签与轴线之间的距离
            interval: 0, //横轴信息全部显示
            textStyle: {
              show: true,
              fontFamily: "微软雅黑",
              color: "#FFFFFF",
              fontSize: "0.13rem",
              // align: 'left'
            },
          },
        },
        series: [
          {
            realtimeSort: true,
            // name: 'X',
            type: "bar",
            animation: true,
            animationDuration: 500,
            barWidth: "40%", // 柱子的宽度
            data: data,
            itemStyle: {
              normal: {
                color: this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  1,
                  0,
                  [
                    {
                      offset: 0,
                      color: number.color3, // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: number.color4, // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
            // label: {
            //     formatter: "{c}",
            //     show: true,
            //     position: 'right',
            //     valueAnimation: true,
            //     color: '#3AE6CE',    // 柱状图上字体的颜色
            //     fontSize:'0.13rem',
            // },
          },
        ],
        legend: {
          show: true,
        },
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: "linear",
        animationEasingUpdate: "linear",
      };

      // function run () {
      //     var data = option.series[0].data;
      //     for (var i = 0; i < data.length; ++i) {
      //         if (Math.random() > 0.9) {
      //             data[i] += Math.round(Math.random() * 2000);
      //         }
      //         else {
      //             data[i] += Math.round(Math.random() * 200);
      //         }
      //     }
      canye.setOption(option);
      // }
      //图表自动缩放
      window.addEventListener("resize", function () {
        canye.resize();
      });
      // setTimeout(function() {
      //     run();
      // }, 0);
      // setInterval(function () {
      //     run();
      // }, 3000);
    },
    // 获取右上角数据
    getRightTop() {
      let that = this;
      var data = {
        token: window.getToken(),
        app_id: window.appId,
        time: timestamp(),
      };
      getAjax({
        url: "/map/screen/getHome",
        method: "GET",
        data: data,
        success: function (res) {
          // console.log(res)
          that.number.population = res.data.regular_exercise_population;
          that.number.proportion =
            res.data.regular_exercise_population_proportion;
        },
      });
    },
    // 获取人群画像
    getPeopelPhoto() {
      let that = this;
      let url = window.appHost + `/api/v1/screen/Area/user_analysis`;
      let result = window.ajaxRequest(url);
      // console.log(result);

      // 绘制年龄分布图
      that.age = [];
      result.data.age.forEach((item, index) => {
        that.age.push({
          value: item.parent,
          name: item.title,
        });
      });
      that.drawSportAge(that.age);

      // 绘制时间分布
      that.timer = [];
      result.data.time.forEach((item, index) => {
        that.timer.push({
          value: item.parent,
          name: item.title,
        });
      });
      that.drawSportTime(that.timer);

      // 绘制男女比例图
      that.tinct1.value = result.data.sex[0].parent;
      that.tinct2.value = result.data.sex[1].parent;
      that.pie("malefemaleRatio", that.tinct1); // 男比例
      that.pie("malefemaleRatio2", that.tinct2); // 女比例

      // 绘制运动喜好
      // that.sport.data = [];
      // that.sport.number = [];
      // result.data.like.forEach((item, index) => {
      //   that.sport.data.push(item.title);
      //   that.sport.number.push(item.num);
      // });
      // that.fitnes("movementPreferences", that.sport);
    },
     // 获取人均体育
        getDatacon(){
            let that = this;
            let url = window.appHost+`/api/v1/screen/Sitearea/datacon`
            let result = window.ajaxRequest(url)
            this.$store.commit('dataCon', result.data);
            if (result.data.video && this.currentVideoIndex == 0) {
              if(result.data.video.length > 0) {
                this.currentVideo = result.data.video[0];
                this.playerOptions.sources = [{
                  type: '',
                  src: result.data.video[0],
                }]
              }
            }
        },
        onPlayerEnded(e) {
          this.currentVideoIndex ++ 
          if(this.dataCon.video.length > 0) {
                this.playerOptions.sources = [{
                  type: '',
                  src: this.dataCon.video[this.currentVideoIndex%this.dataCon.video.length],
                }]
                this.currentVideo = this.dataCon.video[this.currentVideoIndex%this.dataCon.video.length]
              }
              let elevideo = document.getElementById("videoID");
              let playPromise = elevideo.play();
              if (playPromise) {
                playPromise.then(() => {
                  
                }).catch(err => {
                  console.log(err)
                })
              }
        },
  },
  mounted() {
    let that = this;
    that.getRightTop();
    that.getDatacon()
    that.getPeopelPhoto();

    that.rightTopTimer = setInterval(() => {
      that.getRightTop();
      that.getDatacon()
      that.getPeopelPhoto();
      
    }, 300000);

    let elevideo = document.getElementById("videoID");
    elevideo.addEventListener('ended', () => {
      this.onPlayerEnded();
    })
  },
  beforeDestroy() {
    clearInterval(this.rightTopTimer);
  },
};
</script>

<style scoped>
@media screen and (max-width: 1000px) {
  .often_text {
    width: 120%;
    margin-left: -0.2rem;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
  }
}
p {
  margin: 0;
  padding: 0;
}
.shaoxingentirety {
  width: 5.06rem;
  position: relative;
}
.shaoxingentirety > div {
  width: 100%;
}
.entiretytop {
  padding: 0.2rem;
  /* height: 2rem; */
  margin-bottom: 0.2rem;
  padding-bottom: 0.1rem;
}
.entiretytop > video {
  width: 3.66rem;
  margin: 0 auto;
  margin-left: 0.5rem;
}

.entiretybottom {
  height: 3.75rem;
  padding: 0.2rem;
  color: #ffffff;
  font-size: 0.18rem;
}

#sportAge,
#sportTime {
  width: 2.4rem;
  height: 1.3rem;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.marginTop20 {
  margin-top: 0.2rem;
}

.marginTop10 {
   margin-top: 0.1rem;
}

.right-borde {
  position: relative;
}
.right-border::after {
    content: '';
    display: block;
    width: 1px;
    height: 2.8rem;
    background: #999;
    position: absolute;
    bottom: 0.2rem;
    left: 2.3rem;
}

.width50 {
  width: 2.48rem;
}

.width60 {
  width: 2.4rem;
}

.width40 {
  width: 2rem;
}

.font16 {
  font-size: 0.16rem;
}

.sex {
  width: 2rem;
  height: 1.3rem;
  margin-top: 0.1rem;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
.sex > div:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sex > img {
  width: 0.48rem;
  height: 0.56rem;
}
/* .malefemale>div{
    width:50%;
} */
#malefemaleRatio,
#malefemaleRatio2 {
  width: 1.3rem;
  height: 1.3rem;
}

.sports {
  width: 2.4rem;
}
#movementPreferences {
  width: 2.4rem;
  height: 3rem;
  margin-left: -15%;
}

/* 第二行 */
.space,
.chart {
  width: 5.06rem;
  /* height:1.19rem; */
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  margin-bottom: 0.18rem;
}

.skipshao > img {
  width: 0.2rem;
  height: 0.2rem;
  vertical-align: middle;
}
.skipshao > span {
  font-size: 0.14rem;
}
@media screen and (max-width: 1000px) {
  .skipshao {
    width: 120%;
    display: flex;
    justify-content: center;
    margin-left: -0.2rem;
  }
  .skipshao > span {
    display: block;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
  }
  .skipshao > img {
    vertical-align: middle;
    margin-left: -0.1rem;
    margin-top: 0.08rem;
    /*-webkit-transform: scale(0.8);*/
  }
}
.floorSpace {
  width: 2.43rem;
  height: 0.8rem;
  font-size: 0.14rem;
  color: #ffffff;
  padding-top: 0.2rem;
}
.right-title {
  /* background: url(../../../assets/images/sport_people_title.png) no-repeat fixed left top 100%; */
  width: 5rem;
  height: 0.4rem;
  margin-top: -0.2rem;
  margin-left: -0.2rem;
}
</style>